@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition-colors duration-200;
  }
  
  .input-field {
    @apply mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent;
  }
  
  .form-label {
    @apply block text-sm font-medium text-gray-700 mb-1;
  }
  
  .card {
    @apply bg-white rounded-lg shadow-md p-6;
  }
  
  .section-title {
    @apply text-2xl font-bold mb-6 text-gray-900;
  }
}

/* Base styles */
body {
  @apply text-gray-800 bg-gray-50;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  @apply bg-gray-100;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-400 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3, h4 {
  margin: 0;
}

.cta-button {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
}

.cta-button.secondary {
  background-color: #6c757d;
}

.section {
  padding: 50px 20px;
}

@media (max-width: 768px) {
  .cta-button {
    width: 100%;
    padding: 15px;
  }
  
  .section {
    padding: 20px 10px;
  }
}

